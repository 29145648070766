// =============================
// selectmain
// Ragle Inc.
// =============================

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  baseUrl: 'https://api.groundworks-dev.selectmain.com/', // Change this to the address of your backend API if different from frontend address
  apiUrl: 'https://api.groundworks-dev.selectmain.com/odata/v1.0',  // Change this to the address of your backend API if different from frontend address
  tokenUrl: 'https://api.groundworks-dev.selectmain.com', // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl
  loginUrl: '/login'
};
